export const LOAD_USERLIST = "LOAD_USERLIST";
export const SET_USERLIST = "SET_USERLIST";
export const DELETE_USER = "DELETE_USER";
export const POST_USER = "POST_USER";
export const UPDATE_USER = "UPDATE_USER";
export const REQUEST_RESET = "REQUEST_RESET";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const ADMIN_RESET_PASSWORD = "ADMIN_RESET_PASSWORD";
export const SIGNUP_USER = "SIGNUP_USER";
export const AUTHENTICATE_USER = "AUTHENTICATE_USER";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_OG_CURRENT_USER = "SET_OG_CURRENT_USER";
export const LOAD_EMPLOYEE = "LOAD_EMPLOYEE";
export const SET_EMPLOYEE = "SET_EMPLOYEE";
export const PING = "PING";
export const GET_USER = "GET_USER";

export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const GET_ORGANIZATION = "GET_ORGANIZATION";
export const DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
export const POST_ORGANIZATION = "POST_ORGANIZATION";
export const UPDATE_ORG_ASSIGNMENT = "UPDATE_ORG_ASSIGNMENT";

export const APPROVE_USER = "APPROVE_USER";
export const APPROVE_ALL_USER = "APPROVE_ALL_USER"
export const SET_PENDING_USER = "SET_PENDING_USER"
export const GET_PENDING_USER = "GET_PENDING_USER"

export const LOAD_USER_SURVEY = "LOAD_USER_SURVEY";
export const SET_USER_SURVEY = "SET_USER_SURVEY";