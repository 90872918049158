import React, { useEffect, useState } from "react";
import "./index.css";
import { appColor } from "../../../constants";
import { SuccessToast } from "../../../components/Toasts";
import { LOAD_FORMS, PUBLISH_FORM, UNPUBLISH_FORM } from "../../../redux/actions/formActions";
import { useDispatch, useSelector } from "react-redux";

function PublishButton({ formId }) {
    const dispatch = useDispatch();
    const formList = useSelector((state) => state.formReducer.formList);
    const [published, setPublished] = useState(false);

    useEffect(() => {
        dispatch({ type: LOAD_FORMS });
    }, [dispatch]);

    useEffect(() => {
        let form = formList.find((item) => item.form_id === formId);
        if (form?.published) {
            setPublished(true);
        }
    }, [formList, formId]);

    return (
        <button
            className="PublishButton"
            style={{
                backgroundColor: appColor.primaryLight,
                color: appColor.white,
            }}
            onMouseOver={(e) => {
                e.target.style.backgroundColor = appColor.primary;
            }}
            onMouseOut={(e) => {
                e.target.style.backgroundColor = appColor.primaryLight;
            }}
            onClick={() => {
                if (published) {
                    SuccessToast("Form Unpublished!");
                    setPublished(false);
                    dispatch({
                        type: UNPUBLISH_FORM,
                        payload: { form_id: formId },
                    });
                } else {
                    SuccessToast("Form Published!");
                    setPublished(true);
                    dispatch({
                        type: PUBLISH_FORM,
                        payload: { form_id: formId },
                    });
                }
            }}
        >
            {published ? "Unpublish" : "Publish"}
        </button>
    );
};

export default PublishButton;
