export const UPDATE_TICKET_BOARD = "UPDATE_TICKET_BOARD";
export const GET_TICKET_BOARD = "GET_TICKET_BOARD";
export const SET_TICKETS = "SET_TICKETS";
export const UPDATE_TICKET_STATUS = "UPDATE_TICKET_STATUS";
export const UPDATE_TICKET_TITLE = "UPDATE_TICKET_TITLE";
export const UPDATE_TICKET_DESCRIPTION = "UPDATE_TICKET_DESCRIPTION";
export const UPDATE_TICKET_PROJECT = "UPDATE_TICKET_PROJECT";

export const SET_ACTIVE_TICKET = "SET_ACTIVE_TICKET";

export const ASSIGN_USER = "ASSIGN_USER";
export const UNASSIGN_USER = "UNASSIGN_USER";

export const FILTER_TICKETS = "FILTER_TICKETS";

export const GET_SERVICE_COST = "GET_SERVICE_COST";
export const SET_SERVICE_COST = "SET_SERVICE_COST";
export const REMOVE_SERVICE_COST = "REMOVE_SERVICE_COST";
export const POST_SERVICE_COST = "POST_SERVICE_COST";

export const GET_SUBTASKS = "GET_SUBTASKS";
export const SET_SUBTASKS = "SET_SUBTASKS";
export const ADD_SUBTASKS = "ADD_SUBTASKS";

export const GET_ATTACHMENTS = "GET_ATTACHMENTS";
export const SET_ATTACHMENTS = "SET_ATTACHMENTS";
export const CLEAR_ATTACHMENTS = "CLEAR_ATTACHMENTS";
export const DELETE_VIEW_SUMMARY = "DELETE_VIEW_SUMMARY";
