import ContactInfo from "../components/ContactInfo";
import Dropdown from "../components/Dropdown";
import FileDownload from "../components/FileDownload";
import FileInput from "../components/FileInput";
import FormInfo from "../components/FormInfo";
import Heading from "../components/Heading";
import MultiSelect from "../components/MultiSelect";
import ProjectSelector from "../components/ProjectSelector";
import SingleSelect from "../components/SingleSelect";
import TextAnswer from "../components/TextAnswer";
import TextLine from "../components/TextLine";

// Input:
// - List of all answers
// - Question Object
// Output:
// - List of options sorted by the created time
function getQuestionOptions(answerList, question) {
  var optionList = answerList[question.question_id ?? ""] ?? [];
  optionList = optionList.sort((a, b) => {
    let fa = a.added_on;
    let fb = b.added_on;

    if (Number(fa)) {
      //sort autogenerated options
      if (+fa < +fb) {
        return -1;
      }
      if (+fa > +fb) {
        return 1;
      }
      return 0;
    }

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  optionList = optionList.filter((option) => option !== "");
  return optionList;
}

// Mapping of Component Name To Actual Component Object
const componentMap = {
  "multi": MultiSelect,
  "single": SingleSelect,
  "text": TextAnswer,
  "dropdown": Dropdown,
  "heading": Heading,
  "textline": TextLine,
  "upload": FileInput,
  "download": FileDownload,
  "contact": ContactInfo,
  "form": FormInfo,
  "project": ProjectSelector,
}

export { getQuestionOptions, componentMap };
