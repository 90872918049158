import defaultAxios from "axios";
import { backend } from "../../constants";

const axios = defaultAxios.create({
    baseURL: backend,
    headers: { "Content-Type": "application/json" },
});

export const publishFormApi = async (payload) => {
    try {
        const token = JSON.parse(localStorage.getItem("currentUser")).token;
        var headers = {
            Authorization: `Bearer ${token}`,
        };
        var data = JSON.stringify({});
        const form = await axios.post(`form/${payload.form_id}`, data, {
            headers: headers,
        });

        return form;
    } catch (err) {
        return console.error(err);
    }
};

export const unpublishFormApi = async (payload) => {
    try {
        const token = JSON.parse(localStorage.getItem("currentUser")).token;
        var headers = {
            Authorization: `Bearer ${token}`,
        };
        var data = JSON.stringify({});
        const form = await axios.post(`form/unpublish/${payload.form_id}`, data, {
            headers: headers,
        });

        return form;
    } catch (err) {
        return console.error(err);
    }
};