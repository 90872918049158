export const SET_BILLABLE = "SET_BILLABLE";
export const SET_OG_BILLABLE = "SET_OG_BILLABLE";

export const LOAD_BILLABLE = "LOAD_BILLABLE";
export const FILTER_BILLABLE = "FILTER_BILLABLE";

export const SET_INVOICE_LIST = "SET_INVOICE_LIST";

export const SET_COSTCENTER = "SET_COSTCENTER";
export const GET_COSTCENTER = "GET_COSTCENTER";
export const DELETE_COSTCENTER = "DELETE_COSTCENTER";
export const POST_COSTCENTER = "POST_COSTCENTER";

export const SET_PROJECT = "SET_PROJECT";
export const GET_PROJECT = "GET_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const POST_PROJECT = "POST_PROJECT";
export const UPDATE_PROJECT_ASSIGNMENT = "UPDATE_PROJECT_ASSIGNMENT";
export const SET_ACTIVE_ANALYTICS = "SET_ACTIVE_ANALYTICS";
export const SET_ACTIVE_PROJECT_ANALYTICS = "SET_ACTIVE_PROJECT_ANALYTICS";
export const SET_ACTIVE_SOW_ANALYTICS = "SET_ACTIVE_SOW_ANALYTICS";
export const SET_ACTIVE_CONFIRMATION = "SET_ACTIVE_CONFIRMATION";

export const UPDATE_CLICKS = "UPDATE_CLICKS";
export const GET_CLICKS = "GET_CLICKS";

export const LOAD_BILLABLE_BY_SOWID = "LOAD_BILLABLE_BY_SOWID";
export const SET_BILLABLE_BY_SOWID = "SET_BILLABLE_BY_SOWID";
export const BILL_BILLABLE = "BILL_BILLABLE";