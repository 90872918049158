import React from "react";

export const CheckBoxIcon = ({
  boxColor,
  checkColor,
  className,
  width = 25,
  height = 25,
}) => {
  return (
    <div className={className}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill={"none"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.4375 14H1.5625C1.1481 14 0.750671 13.8354 0.457646 13.5424C0.16462 13.2493 0 12.8519 0 12.4375V1.5625C0 0.6995 0.7 0 1.5625 0H12.4375C13.3005 0 14 0.7 14 1.5625V12.4375C14 13.3005 13.3 14 12.4375 14Z"
          fill={boxColor}
        />
        <path
          d="M5.62114 10.4999C5.42964 10.4999 5.23814 10.4284 5.09164 10.2849L2.21964 7.4639C2.15027 7.39623 2.09515 7.31536 2.05751 7.22605C2.01988 7.13675 2.00049 7.04082 2.00049 6.9439C2.00049 6.84699 2.01988 6.75106 2.05751 6.66176C2.09515 6.57245 2.15027 6.49158 2.21964 6.4239C2.36129 6.2858 2.5513 6.2085 2.74914 6.2085C2.94698 6.2085 3.13699 6.2858 3.27864 6.4239L5.62114 8.7244L10.7216 3.7154C10.8633 3.5773 11.0533 3.5 11.2511 3.5C11.449 3.5 11.639 3.5773 11.7806 3.7154C11.8501 3.78305 11.9052 3.86391 11.9429 3.95322C11.9806 4.04252 12 4.13847 12 4.2354C12 4.33234 11.9806 4.42828 11.9429 4.51759C11.9052 4.6069 11.8501 4.68776 11.7806 4.7554L6.15064 10.2844C6.00942 10.4232 5.81914 10.5006 5.62114 10.4999Z"
          fill={checkColor}
        />
      </svg>
    </div>
  );
};
