export const SET_LOADING = "SET_LOADING"
export const SET_QUESTION = "SET_QUESTION"
export const LOAD_QUESTION = "LOAD_QUESTION"
export const SAVE_QUESTION = "SAVE_QUESTION"
export const DELETE_QUESTION = "DELETE_QUESTION"
export const SET_ANSWER = "SET_ANSWER"
export const SAVE_ANSWER = "SAVE_ANSWER"
export const DELETE_ANSWER = "DELETE_ANSWER"
export const REPLACE_QUESTION = "REPLACE_QUESTION"

export const LOAD_ANSWER_BY_SURVEY = "LOAD_ANSWER_BY_SURVEY"
export const SET_ANSWER_BY_SURVEY = "SET_ANSWER_BY_SURVEY"