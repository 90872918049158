import "./index.css";
import "../index.css";
import Divider from "../Divider";

function Heading({ question }) {
  return (
    <div className="GlobalCustomerQuestionContainer">
      {question.position_index > 1 ? <Divider extended /> : null}
      <div className="QuestionTitle">{question.question}</div>
    </div>
  );
}

export default Heading;
