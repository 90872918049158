// Input:
// - User ID
// - List of Colours
// Output:
// - Random index of a colour in the list of colours
const getColorNum = (id, colorArray) => {
    if (colorArray) {
        let colorNum = 0;
        for (let i = 0; i < id.length; i++) {
            colorNum += id.charCodeAt(i);
        }
        const mod = colorNum % Object.keys(colorArray).length;
        return mod;
    } else {
        return 0;
    }
};

export { getColorNum };
