// Hold all elements to be shown on Components Side View
// And their initial order - which changes during runtime as elements are dragged around
import { v4 as uuidv4 } from "uuid";
import "./component-sideview-data.css";
import {
  ContactInfoCard,
  DropdownCard,
  FileDownloadCard,
  FileUploadCard,
  FormInfoCard,
  HeadingCard,
  MultipleChoiceCard,
  ProjectSelectorCard,
  SingleSelectionCard,
  TextAnswerCard,
  TextLineCard
} from "./draggableCards";

export const componentsSideViewData = {
  components: {
    multi: {
      id: "multi",
      component: MultipleChoiceCard,
    },
    single: {
      id: "single",
      component: SingleSelectionCard,
    },
    text: {
      id: "text",
      component: TextAnswerCard,
    },
    dropdown: {
      id: "dropdown",
      component: DropdownCard,
    },
    heading: {
      id: "heading",
      component: HeadingCard,
    },
    textline: {
      id: "textline",
      component: TextLineCard,
    },
    upload: {
      id: "upload",
      component: FileUploadCard,
    },
    download: {
      id: "download",
      component: FileDownloadCard,
    },
    contact: {
      id: "contact",
      component: ContactInfoCard,
    },
    form: {
      id: "form",
      component: FormInfoCard,
    },
    project: {
      id: "project",
      component: ProjectSelectorCard,
    },
  },
  sections: {
    "question-elements": {
      id: "question-elements",
      title: "Question Elements",
      componentIds: ["multi", "single", "text", "dropdown"],
    },
    "layout-elements": {
      id: "layout-elements",
      title: "Layout Elements",
      componentIds: ["heading", "textline"],
    },
    "media-elements": {
      id: "media-elements",
      title: "Media Elements",
      componentIds: ["upload", "download"],
    },
    "billing-elements": {
      id: "billing-elements",
      title: "Billing Elements",
      componentIds: ["contact", "form", "project"],
    },
  },
  //For reordering the sections
  sectionOrder: [
    "question-elements",
    "layout-elements",
    "media-elements",
    "billing-elements",
  ],
};

export const QuestionData = {
  droppedComponents: [
    { originId: "heading", id: uuidv4(), component: HeadingCard },
  ],
  droppedComponentsOrder: [],
};
