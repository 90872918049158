export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_RESPONSE = "ADD_RESPONSE";
export const REMOVE_RESPONSE = "REMOVE_RESPONSE";
export const REMOVE_SINGLE_RESPONSE = "REMOVE_SINGLE_RESPONSE";
export const REPLACE_PROJECT_RESPONSE = "REPLACE_PROJECT_RESPONSE";

export const REMOVE_ALL_RESPONSE = "REMOVE_ALL_RESPONSE";

// Contact Info Actions
export const ADD_FULLNAME_RESPONSE = "ADD_FULLNAME_RESPONSE";
export const REMOVE_FULLNAME_RESPONSE = "REMOVE_FULLNAME_RESPONSE";
export const ADD_INSTITUTION_RESPONSE = "ADD_INSTITUTION_RESPONSE";
export const REMOVE_INSTITUTION_RESPONSE = "REMOVE_INSTITUTION_RESPONSE";
export const ADD_EMAIL_RESPONSE = "ADD_EMAIL_RESPONSE";
export const REMOVE_EMAIL_RESPONSE = "REMOVE_EMAIL_RESPONSE";
export const ADD_PHONE_RESPONSE = "ADD_PHONE_RESPONSE";
export const REMOVE_PHONE_RESPONSE = "REMOVE_PHONE_RESPONSE";
export const ADD_FINANCIAL_RESPONSE = "ADD_FINANCIAL_RESPONSE";
export const REMOVE_FINANCIAL_RESPONSE = "REMOVE_FINANCIAL_RESPONSE";
export const ADD_INVESTIGATOR_RESPONSE = "ADD_INVESTIGATOR_RESPONSE";
export const REMOVE_INVESTIGATOR_RESPONSE = "REMOVE_INVESTIGATOR_RESPONSE";
export const ADD_WORKTAG_RESPONSE = "ADD_WORKTAG_RESPONSE";
export const REMOVE_WORKTAG_RESPONSE = "REMOVE_WORKTAG_RESPONSE";
export const ADD_ADDRESS_RESPONSE = "ADD_ADDRESS_RESPONSE";
export const REMOVE_ADDRESS_RESPONSE = "REMOVE_ADDRESS_RESPONSE";
export const ADD_ACCOUNT_RESPONSE = "ADD_ACCOUNT_RESPONSE";
export const REMOVE_ACCOUNT_RESPONSE = "REMOVE_ACCOUNT_RESPONSE";

export const ADD_OTHER_RESPONSE = "ADD_OTHER_RESPONSE";
export const REMOVE_OTHER_RESPONSE = "REMOVE_OTHER_RESPONSE";

export const ADD_CLINICAL_RESPONSE = "ADD_CLINICAL_RESPONSE";
export const REMOVE_CLINICAL_RESPONSE = "REMOVE_CLINICAL_RESPONSE";

export const SUBMIT_FORM = "SUBMIT_FORM";

export const SAVE_FORM = "SAVE_FORM";
export const LOAD_FORMS = "LOAD_FORMS";
export const LOAD_PUBLISHED_FORMS = "LOAD_PUBLISHED_FORMS";
export const SET_FORMS = "SET_FORMS";
export const SET_PUBLISHED_FORMS = "SET_PUBLISHED_FORMS";
export const DELETE_FORM = "DELETE_FORM";

export const PUBLISH_FORM = "PUBLISH_FORM";
export const UNPUBLISH_FORM = "UNPUBLISH_FORM";
export const SUBMIT_SURVEY = "SUBMIT_SURVEY";
export const SAVE_SURVEY_ID = "SAVE_SURVEY_ID";

export const ADD_DRAFT = "ADD_DRAFT";
export const DELETE_DRAFT = "DELETE_DRAFT";
export const DELETE_ALL_DRAFTS = "DELETE_ALL_DRAFTS";
export const LOAD_DRAFT = "LOAD_DRAFT";
export const LOAD_ALL_DRAFTS = "LOAD_ALL_DRAFTS";
export const SET_DRAFTS = "SET_DRAFTS";
export const SET_ALL_DRAFTS = "SET_ALL_DRAFTS";