import "./index.css";
import { appColor } from "../../constants";

function Divider({ extended = false }) {
  if (extended) {
    return (
      <div className="ExtendedDividerSection">
        <div className="ExtendedDivider" style={{ backgroundColor: appColor.primaryBlack }} />
        <div className="ExtendedDivider" style={{ backgroundColor: appColor.primaryBlack }} />
      </div>
    );
  }
  return (
    <div className="Divider" style={{ backgroundColor: appColor.primaryBlack }} />
  );
}

export default Divider;
