import React from "react";
import { LibraryCard } from "../Card";
import MCIcon from "../../assets/MultiChoice.png";
import SCIcon from "../../assets/SingleChoice.png";
import TextAnswerIcon from "../../assets/TextAnswer.png";
import DropIcon from "../../assets/Dropdown.png";
import HeadingIcon from "../../assets/Heading.png";
import TextIcon from "../../assets/TextLine.png";
import UploadIcon from "../../assets/FileUpload.png";
import DownloadIcon from "../../assets/FileDownload.png";
import ContactIcon from "../../assets/ContactInfo.png";

const DraggableCard = ({ children }) => {
    return (
        <LibraryCard>
            <div className="draggableCardContent">{children}</div>
        </LibraryCard>
    );
};

export const MultipleChoiceCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={MCIcon} alt="Multiple Choice" /> 
            Multiple Choice
        </DraggableCard>
    );
};
export const SingleSelectionCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={SCIcon} alt="Single Selection" />
            Single Selection
        </DraggableCard>
    );
};
export const TextAnswerCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={TextAnswerIcon} alt="Text Answer" />
            Text Answer
        </DraggableCard>
    );
};
export const DropdownCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={DropIcon} alt="Dropdown" />
            Dropdown
        </DraggableCard>
    );
};
export const HeadingCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={HeadingIcon} alt="Heading" />
            Heading
        </DraggableCard>
    );
};
export const TextLineCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={TextIcon} alt="Text Line" />
            Text Line
        </DraggableCard>
    );
};
export const DividerLineCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={TextIcon} alt="Divider Line" />
            Divider Line
        </DraggableCard>
    );
};
export const FileUploadCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={UploadIcon} alt="File  Upload" />
            File Upload
        </DraggableCard>
    );
};
export const FileDownloadCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={DownloadIcon} alt="File Download" />
            File Download
        </DraggableCard>
    );
};
export const ContactInfoCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={ContactIcon} alt="Contact Information" />
            Contact Information
        </DraggableCard>
    );
};
export const FormInfoCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={ContactIcon} alt="Contact Information" />
            Form Information
        </DraggableCard>
    );
};
export const ProjectSelectorCard = () => {
    return (
        <DraggableCard>
            <img className="iconImage" src={ContactIcon} alt="Contact Information" />
            Project Selector
        </DraggableCard>
    );
};